<template>
  <v-container
    id="company"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          icon="mdi-card-text"
          color="yellow darken-1"
        >
          <template #after-heading>
            <div class="display-2 font-weight-light">
              {{ $auth.user().customer.mv_name }}
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$tc('contact', 1)"
                    :value="$auth.user().customer.mv_contact"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$tc('phone', 1)"
                    :value="$auth.user().customer.mv_phone"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$tc('email', 1)"
                    :value="$auth.user().customer.mv_email"
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :label="$tc('address', 1)"
                    :value="$auth.user().customer.mv_street_2"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$tc('postalcode', 1)"
                    :value="$auth.user().customer.mv_postalcode"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$tc('city', 1)"
                    :value="$auth.user().customer.mv_city"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="$tc('country', 1)"
                    :value="countryConvert"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Company',
    computed: {
      countryConvert () {
        const c = this.$auth.user().customer.mv_country
        if (c === '') {
          return 'Nederland'
        } else if (c === 'B') {
          return 'België'
        } else if (c === 'D') {
          return 'Deutschland'
        } else {
          return '-'
        }
      },
    },
  }
</script>
